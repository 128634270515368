<template>
  <div>
    <b-row>
      <b-col md="12" class="mb-3 text-right">
        <b-button class="" variant="outline-primarySpore" @click="showFilter">
          <feather-icon icon="FilterIcon" class="mr-50"/>
          <span class="align-middle" v-if="!show"> Mostrar filtros </span>
          <span class="align-middle" v-else > Ocultar filtros </span>
        </b-button>
      </b-col>
    </b-row>

    <b-card no-body v-if="show">
      <b-card-header class="pb-50">
        <h5> Filtrar </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="2" class="mb-md-0 mb-2">
            <b-form-group label="Número">
              <b-form-input id="number" v-model="filters.number"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <b-form-group label="Nombre">
              <b-form-input id="name" v-model="filters.name"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="7" class="mt-2 text-right">
            <b-button variant="primarySpore" @click="search">
              <feather-icon icon="SearchIcon" class="mr-50"/>
              <span>Buscar</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Mostrar</label>
            <v-select v-model="filters.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getResponse"/>
            <label>Registros</label>
          </b-col>
          <b-col class="my-2">
            <b-button variant="primarySpore" @click="add()" class="float-right">
              <feather-icon icon="PlusCircleIcon" :width="18" :height="18"/>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <h4>Total de cuadrillas: {{ totalRows }}</h4>
          </b-col>
        </b-row>
      </div>
        <div id="data-list-list-view" class="data-list-container mt-2 px-">
          <b-table :fields="fields" :items="response" responsive tbody-tr-class="shadow-sm">
                <template #cell(number)="item">
                  <listItem :value="item.item.number" description="Número" :img="item.item.avatar" class="mt-1"/>
                </template>
                <template #cell(name)="item">
                  <listItem :value="item.item.name" description="Nombre" :img="item.item.avatar" class="mt-1"/>
                </template>
                <template #cell(manager)="item">
                  <listItem :value="item.item.manager" description="Encargado" img="card-text" class="mt-1"/>
                </template>
                <template #cell(uuiduser_group)="item">
                  <b-row>
                    <b-col cols="12" md="12" class="custom-search d-flex justify-content-end">
                      <div class="d-flex align-items-center">
                        <b-button variant="primarySpore" @click="showMembers(item.item.uuid)" title="Miembros" v-b-tooltip.hover="{ placement: 'top' }">
                          <feather-icon icon="UsersIcon"  :width="18" :height="18"/>
                        </b-button>
                      </div>
                      &nbsp;
                      <div class="d-flex align-items-center">
                        <b-button variant="primarySpore" @click="detail(item.item.uuid)" title="Editar" v-b-tooltip.hover="{ placement: 'top' }">
                          <feather-icon icon="EditIcon"  :width="18" :height="18"/>
                        </b-button>
                      </div>
                      &nbsp;
                      <div class="d-flex align-items-center">
                        <b-button variant="danger" @click="confirmDelete(item.item.uuid)" title="Eliminar" v-b-tooltip.hover="{ placement: 'top' }">
                          <feather-icon icon="TrashIcon"  :width="18" :height="18"/>
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <!--<span class="text-muted">Showing {{ count }} of {{ totalRows }} entries</span>-->
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

              <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.filters.limit" first-class last-class class="mb-0 mt-1 mt-sm-0">
                <template #first-text>
                  <feather-icon icon="ChevronsLeftIcon" size="18"/>
                </template>
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
                <template #last-text>
                  <feather-icon icon="ChevronsRightIcon" size="18"/>
                </template>
              </b-pagination>

              </b-col>

            </b-row>
          </div>
        </div>
        <div v-if="response.length == 0" class="noData">No hay registros</div>
      </b-card>
  </div>
</template>

<script>
import request from '@/libs/request/index'
import { app } from '@/main'
import {
  BButton, BCard, BCardHeader, BCardBody, BRow, BCol, BPagination, BTable, BFormInput, BFormGroup
} from 'bootstrap-vue'
import listItem from '@core/spore-components/listItem/listItem.vue'

const DATA = {
  code: 200,
  data: {
    count: 2,
    rows: []
  },
  msg: 'Ok'
}

export default {
  components: {
    BButton, BCard, BCardHeader, BCardBody, BRow, BCol, listItem, BPagination, BTable, BFormInput, BFormGroup
  },
  data () {
    return {
      response: [],
      DATA,
      uuid: this.$route.params.uuid,
      show: false,
      fields: [
        { key: 'number', label: 'Número' },
        { key: 'name', label: 'Nombre' },
        { key: 'manager', label: 'Encargado' },
        { key: 'uuiduser_group', thClass: 'text-right', label: 'Acciones' }
      ],
      totalRows: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      count: 0,
      filters: {
        limit: 20,
        offset: 0,
        byColumn: 'id',
        byOrder: 'ASC',
        number: '',
        name: ''
      }
    }
  },

  methods: {
    showFilter () {
      this.show = !this.show
    },
    search () {
      this.filters.offset = 0
      this.currentPage = 1
      this.getResponse()
    },
    async getResponse () {
      const params = {
        url: '/teams',
        method: 'GET',
        params: this.filters
      }
      await request(params).then(data => {
        this.response = data.data.data.rows
        this.totalRows = data.data.data.count
        this.count = data.data.data.rows.length
      })
    },
    confirmDelete (uuid) {
      this.$swal({
        title: '¿Deseas eliminar el registro?',
        text: 'Esperando confirmación del usuario',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `teams/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.getResponse()
          })
        }
      })
    },
    detail (uuid) {
      app.$router.push(`/teams/${uuid}/detail`)
    },
    showMembers (uuid) {
      app.$router.push(`/teams/${uuid}/members`)
    },
    add () {
      this.$router.push('/teams/create')
    }
  },
  created () {
    this.getResponse()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getResponse()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/ListGroups.scss";
</style>
